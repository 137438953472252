import { useEffect as w, useId as R } from "react";
import { jsx as I } from "react/jsx-runtime";
import { setLoginFlowState as $ } from "@vgno/account";
import './assets/index.css';const O = () => {
  try {
    const e = document.querySelector("#overlay-manifest");
    if (!e || !e.textContent)
      throw new Error("Could not find VGTV overlay manifest");
    return JSON.parse(e.textContent);
  } catch (e) {
    return console.warn("Failed to load VGTV overlay manifest", e), null;
  }
}, x = () => {
  const e = document.createElement("div");
  e.id = "vgtv-overlay", document.body.appendChild(e);
}, V = (e) => {
  try {
    new URL(e);
  } catch {
    throw new Error(`Invalid stylesheet URL: ${e}`);
  }
  const t = document.createElement("link");
  t.rel = "stylesheet", t.href = e, document.head.appendChild(t);
}, A = () => {
  const e = [
    { src: "https://vgc.no/player/player.next.min.bundled-latest.js" },
    { src: "https://vgc.no/player/player-plugin-skin-vgtv2-latest.js" },
    {
      as: "style",
      src: "https://vgc.no/player/player-skin-vgtv2-latest.css"
    }
  ], t = document.createDocumentFragment();
  for (const { as: s = "script", src: n } of e) {
    const r = document.createElement("link");
    r.href = n, r.rel = "preload", r.as = s, t.appendChild(r);
  }
  document.head.appendChild(t);
}, M = () => "noModule" in HTMLScriptElement.prototype, j = () => {
  try {
  } catch {
    return !1;
  }
  return !0;
}, T = (e) => e.dataset.useOverlayPlayer === "true", z = (e) => {
  const t = new URL(e);
  history.pushState({}, "", t.pathname + t.search), dispatchEvent(new PopStateEvent("popstate", { state: {} }));
}, F = async () => {
  if (!j() || !M())
    return;
  const e = O();
  e && (x(), V(e["vivi.css"]), await import(
    /* @vite-ignore */
    e["vivi.js"]
  ), A(), document.addEventListener("click", (t) => {
    var n;
    const s = (n = t.target) == null ? void 0 : n.closest(
      "a[href*='https://tv.vg.no/video/']"
    );
    s && T(s) && (t.preventDefault(), z(s.href));
  }));
}, de = () => (w(() => {
  F();
}, []), null);
var q = (e, t) => async ({
  assetIds: s
}, n) => {
  if (!s.length)
    return { items: [] };
  const r = new URLSearchParams({ assetId: [...s].sort().join(",") });
  return t && r.set("provider", t), (await e("/items", { ...n, searchParams: r })).json();
}, B = (e, t) => async ({
  assetId: s,
  playlistId: n
}, r) => {
  const o = new URLSearchParams({ assetId: String(s) });
  return n && o.set("playlistId", n), t && o.set("provider", t), (await e("/related-items", {
    ...r,
    searchParams: o
  })).json();
}, N = (e, t) => ({
  getItems: q(e, t),
  getRelatedItems: B(e, t)
}), D = class extends Error {
  constructor(e) {
    const t = e.status || e.status === 0 ? e.status : "", s = e.statusText || "", n = `${t} ${s}`.trim(), r = n ? `status code ${n}` : "an unknown error";
    super(`Request failed with ${r}`), this.name = "HttpError", this.response = e;
  }
}, J = () => {
  try {
    if (typeof window < "u" && "fetch" in window)
      return fetch;
    if ("fetch" in globalThis)
      return fetch;
  } catch (e) {
    console.error('failed to resolve "fetch"', e);
  }
}, H = (e) => {
  if (!e)
    return {
      signal: void 0,
      clear: () => {
      }
    };
  const t = new AbortController(), s = setTimeout(() => {
    t.abort();
  }, e), n = () => {
    clearTimeout(s);
  };
  return {
    signal: t.signal,
    clear: n
  };
}, G = ({
  baseUrl: e,
  timeout: t,
  fetch: s = J()
}) => {
  const n = e.replace(/\/$/, "");
  return async (r, o = {}) => {
    const { searchParams: a, timeout: i = t, ...c } = o, u = H(i);
    let d = `${n}${r}`;
    if (a) {
      const v = new URLSearchParams(a).toString();
      d += `?${v}`;
    }
    const l = await s(d, { ...c, signal: u.signal });
    if (u.clear(), !l.ok)
      throw new D(l);
    return l;
  };
}, W = () => `${window.location.origin}/stories`, Y = (e, t) => {
  let s = `${e}/embed`;
  t && (s += `?provider=${t}`);
  const n = document.createElement("iframe");
  return n.setAttribute("allow", "autoplay; web-share"), n.setAttribute("src", s), n.style.position = "fixed", n.style.left = "0px", n.style.right = "0px", n.style.top = "0px", n.style.bottom = "0px", n.style.display = "none", n.style.width = "100%", n.style.height = "100%", n.style.border = "none", n.style.backgroundColor = "#fff", n.className = "stories-overlay", n;
}, S = (e) => {
  try {
    const { pathname: t } = new URL(e), { groups: s } = t.match(/^\/stories\/(?<id>(\d+))/) || {};
    return parseInt((s == null ? void 0 : s.id) || "", 10) || void 0;
  } catch {
    return;
  }
}, K = class extends EventTarget {
  constructor(e = {}) {
    var t, s;
    super(), this.isIframeReady = !1, this.state = "closed", this.baseUrl = (t = e.baseUrl) != null ? t : W(), this.items = /* @__PURE__ */ new Map(), this.provider = e.provider, this.storiesApi = N(
      G({ baseUrl: `${this.baseUrl}/api`, timeout: (s = e.timeout) != null ? s : 1500 }),
      this.provider
    );
  }
  init() {
    if (this.iframe)
      return;
    this.iframe = Y(this.baseUrl, this.provider), document.body.appendChild(this.iframe);
    const e = (t) => {
      try {
        const s = JSON.parse(t.data);
        switch (s.type) {
          case "overlay/go-back": {
            this.dispatch("overlay/go-back");
            break;
          }
          case "overlay/state": {
            this.dispatch("overlay/state", s.payload);
            break;
          }
          case "overlay/ready": {
            this.isIframeReady = !0;
            break;
          }
        }
      } catch {
      }
    };
    window.addEventListener("message", e), this.removeIframeListener = () => {
      window.removeEventListener("message", e);
    };
  }
  destroy() {
    var e;
    (e = this.removeIframeListener) == null || e.call(this), this.state = "closed", this.isIframeReady = !1, this.iframe && (document.body.removeChild(this.iframe), this.iframe = void 0);
  }
  async preloadItem(e) {
    await this.getItem(e);
  }
  async preloadItems(e) {
    const t = e.map((r) => S(r)).filter((r) => !!r).filter((r) => !this.items.has(r)), s = this.storiesApi.getItems({ assetIds: t }), n = async (r) => {
      try {
        const { items: o } = await s;
        return o.find((a) => a.type === "asset" && a.asset.id === r);
      } catch (o) {
        console.error(o);
        return;
      }
    };
    t.forEach((r) => {
      this.items.set(r, n(r));
    });
  }
  async openItem(e) {
    const t = this.iframe;
    if (!t)
      return !1;
    const s = await this.getItem(e);
    if (!s)
      return !1;
    const n = this.postMessage({
      type: "overlay/set-item",
      payload: { item: s, url: e }
    });
    return n && (this.dispatch("overlay/open"), this.state = "open", t.style.display = "unset"), n;
  }
  getItem(e) {
    const t = S(e);
    if (!t)
      return;
    let s = this.items.get(t);
    return s || (s = this.fetchItem(t), this.items.set(t, s)), s;
  }
  closeOverlay() {
    this.iframe && this.state === "open" && (this.state = "closed", this.iframe.style.display = "none", this.postMessage({ type: "overlay/close" }), this.dispatch("overlay/close"));
  }
  async fetchItem(e) {
    try {
      const { items: t } = await this.storiesApi.getItems({ assetIds: [e] });
      return t.find((s) => s.type === "asset" && s.asset.id === e);
    } catch (t) {
      console.error(t);
      return;
    }
  }
  postMessage(e) {
    return this.iframe && this.iframe.contentWindow && this.isIframeReady ? (this.iframe.contentWindow.postMessage(JSON.stringify(e), "*"), !0) : !1;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch(e, t) {
    const s = t ? new CustomEvent(e, { detail: t }) : new Event(e);
    this.dispatchEvent(s);
  }
};
const Q = () => {
  const { hostname: e } = window.location;
  return e === "stage.vg.no" ? "https://stage.vg.no/stories" : "https://www.vg.no/stories";
}, X = () => {
  const e = new K({ baseUrl: Q() });
  let t = document.title, s;
  return window.addEventListener("popstate", () => {
    const { pathname: n } = new URL(location.href);
    n.match(/\/stories\/\d+/) ? e.openItem(location.href) : e.closeOverlay();
  }), e.addEventListener("overlay/open", () => {
    t = document.title, s = document.body.style.getPropertyValue("overflow"), document.body.style.overflow = "hidden";
  }), e.addEventListener("overlay/close", () => {
    t && (document.title = t), s !== void 0 && (document.body.style.overflow = s), t = "", s = void 0;
  }), e.addEventListener("overlay/state", ({ detail: n }) => {
    const { path: r, title: o } = n;
    window.history.replaceState({}, "", window.location.origin + r), document.title = o;
  }), e.addEventListener("overlay/go-back", () => {
    history.back();
  }), e;
}, Z = () => {
  const e = window.__stories_overlay ?? X();
  window.__stories_overlay = e, e.init();
  const t = async (s) => {
    const { pathname: n, search: r } = new URL(s), o = n + r;
    await e.openItem(s) ? history.pushState({}, "", window.location.origin + o) : window.location.href = s;
  };
  document.addEventListener("click", (s) => {
    var r;
    const n = (r = s.target) == null ? void 0 : r.closest(
      "a[href*='https://www.vg.no/stories/']"
    );
    n && (s.preventDefault(), t(n.href));
  });
}, pe = () => (w(() => {
  Z();
}, []), null), ue = () => window.__stories_overlay, _ = "https://www.vg.no/vgc/player/player.next.min.bundled-latest.js";
let E = !1;
const ee = () => {
  if (!window || E)
    return;
  E = !0;
  const e = document.createElement("script");
  e.src = _, e.async = !0, document.head.appendChild(e);
}, L = (e) => new Promise((t) => {
  window[e] ? t(window[e]) : window.addEventListener("identity-initialized", () => {
    t(window[e]);
  });
}), te = async (e) => {
  const { id: t, assetType: s } = e.rawAsset, n = s === "video" ? `https://api.vg.no/svp/token/v2/${t}?access=plus` : `https://api.vg.no/svp/token/${t}?access=plus`;
  try {
    const r = await fetch(n, {
      credentials: "include"
    });
    if (!r.ok)
      throw new Error("Failed to fetch user status");
    const o = await r.json();
    return { hasProduct: o.hasAccess, loggedIn: o.loggedIn };
  } catch {
    return { hasProduct: !1, loggedIn: !1 };
  }
}, se = (e = ee) => {
  let t = [], s = !1;
  const n = (i) => {
    const c = window.SVP.getPlayerConfig(
      {
        ads: {
          bumper: !0,
          midroll: !0,
          pausead: !0
        },
        context: i.options.context,
        noFirstAd: i.options.noFirstAd,
        paywallAccess: {
          enabled: !0,
          getSpidIdentityInstance: () => L("SPiD_Identity"),
          getSpidMonetizationInstance: () => L("SPiD_Monetization"),
          message: {
            onLoginClick: () => {
              var d, l;
              const u = $();
              (l = window.SPiD_Identity) == null || l.login({
                redirectUri: (d = window.SPiD_Identity) == null ? void 0 : d.redirectUri,
                state: u
              });
            }
          }
        },
        site: "vg"
      },
      {
        ...i.config,
        userStatus: te
      }
    );
    return new window.SVP.Player(c);
  }, r = () => typeof window.SVP < "u" && !!window.SVP.Player;
  return {
    initLibrary: () => {
      s || (s = !0, window.addEventListener("onSvpPlayerReady", () => {
        t.forEach((i) => i()), t = [];
      }), e());
    },
    loadVideo: (i) => new Promise((c) => {
      r() ? c(n(i)) : t.push(() => c(n(i)));
    })
  };
}, P = se(), ne = () => {
  if (!(typeof window > "u"))
    return P.initLibrary(), P.loadVideo;
}, re = "_playerContainer_1q8hp_1", oe = "_podcastContainer_1q8hp_7", k = {
  playerContainer: re,
  podcastContainer: oe
}, h = {
  audio: "https://vgc.no/player/player-skin-vgtv-latest.css",
  video: "https://vgc.no/player/player-skin-vgtv2-latest.css"
}, ie = (e) => `https://www.vg.no/nyheter/i/jPJ5g9/vg?utm_content=kjopvgpluss&utm_source=vgno&asset_type=video&asset_id=${e}&asset_provider=vgtv`, he = ({
  assetId: e,
  assetType: t = "video",
  autoplayMuted: s = !1,
  className: n,
  context: r = null,
  enableAutoPlay: o = !1,
  loading: a = "lazy",
  loop: i = !1,
  na: c = !1,
  noFirstAd: u = !1,
  videoPreview: d = !1,
  vendor: l = "vgtv",
  ...v
}) => {
  const p = ne(), C = R(), y = `svp-video-${e}-${C}`, m = {
    name: t,
    url: t in h ? h[t] : h.video
  };
  return w(() => {
    const g = {
      context: r,
      noFirstAd: u
    }, b = {
      autoplay: o,
      id: Number(e),
      mute: s,
      node: y,
      repeat: i,
      settings: {
        na: c || t === "audio"
      },
      skin: m.name === "video" ? void 0 : m,
      subscriptionUrl: ie(e),
      vendor: l,
      videoPreview: d
    };
    if (a === "eager") {
      p == null || p({
        config: b,
        options: g
      });
      return;
    }
    const f = new IntersectionObserver(
      (U) => {
        U[0].isIntersecting && (f.disconnect(), p == null || p({
          config: b,
          options: g
        }));
      },
      {
        rootMargin: "100%"
      }
    );
    return f.observe(document.getElementById(y)), () => f.disconnect();
  }, [e, p, o, s, a, i]), /* @__PURE__ */ I(
    "div",
    {
      ...v,
      className: n || (m.name === "audio" ? k.podcastContainer : k.playerContainer),
      children: /* @__PURE__ */ I("div", { id: y })
    }
  );
}, ve = [
  {
    as: "style",
    href: h.video
  },
  {
    as: "script",
    href: _
  }
];
export {
  de as OverlayPlayer,
  pe as StoriesOverlay,
  he as Video,
  ue as getStoriesOverlay,
  z as openOverlay,
  ve as preloadAssets
};
